import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL + "/clli";

class ClliService {

    createClli(clli){
        return axios.post(baseURL + "/save", clli);
    }

    getClliById(clliCd){
        return axios.get(baseURL + '/search?idNum=' + clliCd);
    }    

    updateClli(clli){
        return axios.post(baseURL, clli);
    }

    deleteClli(clliCd){
        return axios.post(baseURL + '/delete?clliCd=' + clliCd);
    }

    deleteClliById(idNum){
        return axios.post(baseURL + '/delete?idNum=' + idNum);
    }

    deleteBatchClli(clli){
        return axios.post(baseURL + '/deleteall',  clli);
    }

    
}

export default new ClliService()