import React, { Component, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import flist from '../../images/filter-list.svg';
import Select, { components } from "react-select";
import style from '../../css/table.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import modalStyle from '../../css/globaltable.module.css';

class ShowModal extends Component {
    constructor(props) {
      super(props);


      this.state = {
        optionsCounty:[],
        preFilteredRows:this.props.preFilteredRows,
        tableFilteredRows:this.props.filteredRows,
        stateCountFilter:this.props.optionsStateValue?this.props.optionsStateValue.length:0,
        countyCountFilter:this.props.optionsCountyValue?this.props.optionsCountyValue.length:0,
        totalCountRows:this.props.currentTableData?this.props.currentTableData.length:0,
        stateFilters:[],
        countyFilters:[],
        optionsValue:[],
        optionsStateValue:this.props.optionsStateValue,
        optionsCountyValue:this.props.optionsCountyValue,
        currentTableData:this.props.currentTableData
      };

    }



    onClearState = () => {
      if(this.props.selectInputStateRef && this.props.selectInputStateRef.current){
        this.props.selectInputStateRef.current.clearValue();
      }
      if(this.props.selectInputCountyRef && this.props.selectInputCountyRef.current){
        this.props.selectInputCountyRef.current.clearValue();
      }
      
      
      this.setState(this.state.optionsStateValue=[]);
      this.setState(this.state.optionsCountyValue=[]);
      this.addFilterCountTotal(0);
      this.addFilterToStateTotal(0);
      this.addFilterToCountyTotal(0);
    };

    onClearCounty = () => {
      this.props.selectInputCountyRef.current.clearValue();
    };


    countyOptionsState = (countyOptions) => {
      this.setState(this.state.optionsCounty=countyOptions) 
    }

    addFilterToStateTotal = (e) => {
      const item = e;
      this.setState(this.state.stateCountFilter=[item]);
      
    };

    addFilterToCountyTotal = (e) => {
      const item = e;
      this.setState(this.state.countyCountFilter=[item]);
    };

    addFilterCountTotal = (e) => {
      const item = e;
      this.setState(this.state.totalCountRows=[item]);
    }

    addFiltersToTotal = (filterName, id) => {

      if(id == "stateCd"){
        this.setState(this.state.stateFilters=filterName);
      }
      if(id == "countyName"){
        this.setState(this.state.countyFilters=filterName);
      }

    };

    handleChangeState = (value) => {
      this.setState(this.state.optionsStateValue=value)
    };


    handleChangeCounty = (value) => {
        this.setState(this.state.optionsCountyValue=value)
    };

   filterDataCounty = () => {
       const tempData = this.state.preFilteredRows;
       const filterData = tempData.filter((item) => {
        if (Object.keys(this.state.countyFilters).length > 0) {
          for (let key in this.state.countyFilters) {
            if (item.values[key] === undefined || !this.state.countyFilters[key].includes(item.values[key])) {
              return false;
            }
          }
        }
        return true;
      })


      if(filterData.length>0)
      {
        this.setState(this.addFilterCountTotal(filterData.length));
      }
      else{
        this.setState(this.addFilterCountTotal(this.state.preFilteredRows.length));
      }
      

    }  

    filterData = ({countyOptionsState}) => {
        this.setState(this.state.preFilteredRows=this.props.preFilteredRows)

        const filterData = this.state.preFilteredRows.filter((item) => {
          if (Object.keys(this.state.stateFilters).length > 0) {
            for (let key in this.state.stateFilters) {
              if (item.values[key] === undefined || !this.state.stateFilters[key].includes(item.values[key])) {
                return false;
              }
            }
          }
          return true;
        })


        
        this.onClearCounty();
        if(filterData.length>0){
            this.setState(this.state.preFilteredRows=filterData);
            this.setState(this.addFilterCountTotal(filterData.length));
            
        }
        else{
          this.setState(this.addFilterCountTotal(this.state.preFilteredRows.length));
        }

        const optionsGeneralCounty = new Set()
        optionsGeneralCounty.add({value: "*", label: "Select all"});
  
        this.state.preFilteredRows.forEach(row => {
          optionsGeneralCounty.add({value:row.values["countyName"], label:row.values["countyName"]})
        })

        
        countyOptionsState([...optionsGeneralCounty.values()]);
    };   


    handleClose = () => {

      
     if(this.state.stateFilters.stateCd || this.state.countyFilters.countyName){
        this.props.onChangeFilterModal(this.state.stateFilters, this.state.countyFilters);
        this.props.setStateFilters(this.state.stateFilters);
        this.props.setcountyFilters(this.state.countyFilters);
      }
      this.props.setOptionsStateValue(this.state.optionsStateValue);
      this.props.setOptionsCountyValue(this.state.optionsCountyValue);
      {this.props.onCloseModal()}
    };
  

    render(

      
    ) 

    {
    
  
      const { options, onChange, value, multi,handleChangeState, handleChangeCounty, selectInputStateRef, selectInputCountyRef,
       onClearState, optionsState, optionsCounty,countyOptionsState, onClearCounty,optionsStateValue,optionsCountyValue,currentTableData, ...otherProps } = this.props;

      const newTotal = parseInt(this.state.stateCountFilter) + parseInt(this.state.countyCountFilter);


      return (
        
        <div>
          
           <Modal className={modalStyle.showModal} show={this.props.showModal}>
              <Modal.Header className={modalStyle.showModalHeader}>
                        <Modal.Title className={modalStyle.modalTitle}>
                              <div className="flex"  >
                              <img  className="px-4" src={flist}></img><span style={{ marginTop: '0.5rem' }}>Filters ({newTotal})</span>
                              <Button className={modalStyle.buttonLink} onClick={this.onClearState}  >Reset Filters </Button>      
                              </div>
                        </Modal.Title>
              </Modal.Header>

              <Modal.Body className={modalStyle.showModalBody}>
              
                {this.props.headerGroups.map((headerGroup) =>
        
        
        headerGroup.headers.map((column,index) =>
          column.Filter ? (
           <div key={index}>
            <div  style={column.Header=="State" ? null : { marginTop: '5rem' }}> 
               <div className={modalStyle.showModalLabel}>
               <span className="px-4">{column.render("Header")} {column.Header=="State" ?("(" + this.state.stateCountFilter + ")"):("(" + this.state.countyCountFilter + ")")}</span> 
               
               </div>
            </div>
            <div className="mt-2 sm:mt-0" key={index}>
                 <SelectColumnFilterModal 
                  filteredRows={this.props.filteredRows} 
                  column={column} 
                  tableFilteredRows={this.state.tableFilteredRows} 
                  addFilterToCountyTotal={this.addFilterToCountyTotal} 
                  addFilterToStateTotal={this.addFilterToStateTotal} 
                  onChange={true} 
                  multi={true} 
                  onClearState={this.onClearState}  
                  selectInputCountyRef={this.props.selectInputCountyRef} 
                  selectInputStateRef ={this.props.selectInputStateRef} 
                  addFiltersToTotal={this.addFiltersToTotal}
                  filterData={this.filterData} 
                  optionsCounty={this.state.optionsCounty} 
                  countyOptionsState={this.countyOptionsState} 
                  addFilterCountTotal={this.addFilterCountTotal}
                  filterDataCounty={this.filterDataCounty} 
                  onClearCounty={onClearCounty} 
                  preFilteredRows={this.props.preFilteredRows}  
                  optionsValue={this.state.optionsValue} 
                  setOptionsValue={this.props.setOptionsValue} 
                  handleChangeState={this.handleChangeState}
                  optionsStateValue={this.state.optionsStateValue} 
                  setOptionsStateValue={this.props.setOptionsStateValue}
                  optionsCountyValue={this.state.optionsCountyValue} 
                  setOptionsCountyValue={this.props.setOptionsCountyValue}
                  handleChangeCounty={this.handleChangeCounty}
                  optionsStateValueProp={this.props.optionsStateValue} 
                  currentTableData={this.props.currentTableData}
                 />
                 
            </div>
            </div>
            
          ) : null
        )

      ) }

           </Modal.Body>

           <Modal.Footer className={modalStyle.showModalFooter}>
                        <span className="px-4 py-1">{this.state.totalCountRows} Results Found Based On Filters</span>
                        <div className="grid grid-cols-4">
                            <div className="col-span-3 px-4 py-2">
                                <Button className={modalStyle.buttonPrimary} onClick={this.handleClose}  >Apply</Button>
                                <Button className={modalStyle.buttonSecondary} onClick={this.props.onCloseModal} >Cancel</Button>
                           </div>
                        </div>
                        
             </Modal.Footer>


     </Modal> 
        </div>
      );
    }
  
  }
  

  export function SelectColumnFilterModal({
    filteredRows, 
    column: { id }, 
    tableFilteredRows, 
    addFilterToCountyTotal, 
    addFilterToStateTotal,
    onChange,
    multi,
    onClearState,
    selectInputCountyRef,
    selectInputStateRef,
    addFiltersToTotal,
    filterData,
    optionsCounty,
    countyOptionsState,
    addFilterCountTotal,
    filterDataCounty,
    onClearCounty,
    optionsValue,
    setOptionsValue,
    handleChangeState,
    optionsStateValue,
    setOptionsStateValue,
    optionsCountyValue,
    setOptionsCountyValue,
    handleChangeCounty,
    preFilteredRows,
    optionsStateValueProp,
    currentTableData,
    ...props
  }) {

    const [optionsState, setOptionsState] = React.useState([]);
  
    const [filterCounty, setFilterCounty] = React.useState([]);
    const [filterState, setFilterState] = React.useState([]);
  
    const Option = props => (
      <components.Option {...props} >
      <>
      <div>
          <span>
              <input 
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
          >
          </input>
          <label className={modalStyle.checkboxLabel}  >{props.label}</label>
          </span>
      </div>
      </>    
      </components.Option>
    );

    const MultiValue = props => (
      <components.MultiValue {...props}>
          <span>{props.data.label}</span>
      </components.MultiValue>
    );
  
  
    const CustomStyle = {
      option: (base, state) => {
         let backgroundColor = 'transparent';
         if (state.isSelected) {
           backgroundColor = "white";
         }
   
         if (state.isFocused) {
           backgroundColor = "transparent";
         }
        
         return {
           ...base,
           backgroundColor,
         };
       }
   }

   const optionsGeneral = React.useMemo(() => {
    const optionsGeneral = new Set()
    optionsGeneral.add({value: "*", label: "Select all"});

    preFilteredRows.forEach(row => {
      optionsGeneral.add({value:row.values["stateCd"], label:row.values["stateCd"]})
    })

    
    return [...optionsGeneral.values()]

  } 
  , ["stateCd", tableFilteredRows]
  )

  const optionsGeneralCounty = React.useMemo(() => {
    const optionsGeneralCounty = new Set()
    optionsGeneralCounty.add({value: "*", label: "Select all"});

    currentTableData.forEach(row => {
      optionsGeneralCounty.add({value:row.values["countyName"], label:row.values["countyName"]})
    })


    
    return [...optionsGeneralCounty.values()]

  } 
  , [currentTableData]
  );

  useEffect(() => {

    const combined = optionsGeneral.reduce((acc, el) => {
      if (acc[el.value] === undefined) {
        acc[el.value] = 0;
      }
      acc[el.value] = el.label;
      return acc;
    }, {});
  
    const arrayFinal = Object.entries(combined).map(([value, label]) => ({
      value,
      label
    }));

    setOptionsState(arrayFinal); 
  }, [optionsGeneral]);
  
  
  useEffect(() => {
    setOptionsValue(optionsValue) 
  }, [optionsValue]);


  useEffect(() => {
    countyOptionsState(optionsGeneralCounty);  
  }, [optionsGeneralCounty]);


  const handleRemoveValue = (e,{countyOptionsState}) => {

    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;

    let removedValue = "";
    if(id ==="stateCd"){
        removedValue = optionsStateValue.find((val) => val.value === buttonName);
    }    
    if(id ==="countyName"){
        removedValue = optionsCountyValue.find((val) => val.value === buttonName);
    }    

    if (!removedValue) return;

    if(id==="stateCd"){
      

      handleChange(
          optionsStateValue.filter((val) => val.value !== buttonName),
          {  action: "remove-value", removedValue },
      );  

      handleFilterChange(
        optionsStateValue.filter((val) => val.value !== buttonName),
        {countyOptionsState}
      );
    }

    if(id==="countyName"){
      handleChange(
          optionsCountyValue.filter((val) => val.value !== buttonName),
          {  action: "remove-value", removedValue },
      );  

      handleFilterChange(
        optionsCountyValue.filter((val) => val.value !== buttonName),
        {countyOptionsState}
      );
  
      }
 

  };   


  const handleChange = (selected) => {
    selected.length &&
    selected.find(option => option.value === "*")
      ? (id==="stateCd"?handleChangeState(optionsState.slice(1)):handleChangeCounty(optionsCounty.slice(1)))
      : id==="countyName"?handleChangeCounty(selected):handleChangeState(selected);
   } 

  const handleFilterChange = (selected,countyOptionsState) => {
    var value = selected.length &&
                  selected.find(option => option.value === "*")
                  ? (id=="stateCd"?optionsState.slice(1):optionsCounty.slice(1))
                  : selected;
                

    if(id=="countyName"){
      const countyName = value.map(item => item.value);
      setFilterCounty({countyName});
      addFilterToCountyTotal(value.length);
      addFiltersToTotal({countyName},id);
      filterDataCounty();
    }
    if(id=="stateCd"){
      const stateCd = value.map(item => item.value);
      setFilterState({stateCd});
      value && addFilterToStateTotal(value.length);
      addFiltersToTotal({stateCd},id);
      filterData(countyOptionsState);
    }

  };
  
  
  return (


    <>
        <div >
          <div className="px-4">
          <div className={modalStyle.valuesSelect}>
              <Select 
                styles={CustomStyle}
                closeMenuOnSelect={false}
                isMulti
                components={{ Option, MultiValue}}
                options={id==="stateCd"?optionsState:optionsCounty}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                onChange={(selected,event) => { {handleChange(selected)} {handleFilterChange(selected,{countyOptionsState})}}}
                value={id=="stateCd"?optionsStateValue:optionsCountyValue}
                ref={id ==="countyName" ? selectInputCountyRef : selectInputStateRef}
              >
              </Select>
         </div>                                     
              </div>     
              <div className="py-3 px-4">
              <div className={modalStyle.valuesContainer}>
                  {
                  multi
                  ? 
                  (
                    id==="stateCd"?
                    optionsStateValue.map((val) => (
                    
                  <div className={modalStyle.value} key={val.value}>
                  {val.label}
                  <button className={modalStyle.xButton} name={val.value} onClick={(e) => {{handleRemoveValue(e,{countyOptionsState})}}}>
                      ✕
                  </button>
                  </div>
                  ))
                  :
                  optionsCountyValue.map((val, index) => (
                    
                    <div className={modalStyle.value} key={index}>
                    {val.label}
                    <button className={modalStyle.xButton} name={val.value} onClick={(e) => {{handleRemoveValue(e,{countyOptionsState})}}}>
                        ✕
                    </button>
                    </div>
                    ))
                  
                  )

                  : null}
              </div>                                   


            </div>
       </div>  

    
</>    
    );


}

export default ShowModal;