import React from 'react';
import style from '../../css/layout.module.css';
import Image from '../../logo.png';
import Sidebar from '../../components/sidebar/Sidebar.js';

import { UserIcon } from '@heroicons/react/solid';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const getCurrentYear = () => { return new Date().getFullYear(); };

const handleClick = (e, instance, signOutClickHandler) => {
  e.preventDefault();
  { signOutClickHandler(instance) }
}

const showPopup = (name, user, instance, signOutClickHandler) => {
  return (
    <Popup contentStyle={{ width: '12%' }} trigger={<UserIcon className={style.navIcon} />} position="bottom center">
      <div className={style.accountText}>Account</div>
      <div className={style.accountUserText}>{name}</div>
      <div className={style.accountUserText}>{user}</div>
      <div className={style.line} />
      <button className={style.buttonLink} onClick={(e) => { { handleClick(e, instance, signOutClickHandler) } }} > Sign Out
      </button>
    </Popup>
  )
}


function Layout(props) {
  const { children } = props;

  window.name = props.instance.getActiveAccount() ? props.instance.getActiveAccount().username : "guest@intrado.com";

  return (

    <div className={style.layout}>
      <header className={style.header}>
        <img src={Image} alt="img" className={style.logo} />
        <p className={style.headertext}>ACMC</p>
        <p>{showPopup(props.instance.getActiveAccount() ? props.instance.getActiveAccount().name : "",
          props.instance.getActiveAccount() ? props.instance.getActiveAccount().username : "",
          props.instance,
          props.signOutClickHandler)}</p>
      </header>
      <aside className={style.aside}>
        <Sidebar />
      </aside>
      <main className={style.main}>{children}</main>
      <footer className={style.footer}>
        <p className={style.footertext}>@ {getCurrentYear()} Intrado. All Rights Reserved |</p>


      </footer>
    </div>
  );
}

export default Layout;