import { notification } from 'antd'

export const NotificationType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

export const openNotificationWithIcon = (
  type,
  title,
  message
) => {
  notification[type]({
    duration: 0,
    placement: 'top',
    message: title,
    description: message,
  });
}