import React from 'react';
import { usePagination, DOTS } from '../../components/table/usePagination';

import style from '../../css/pagination.module.css';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    currentTable
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  let rangeMessage = props.totalCount > 0 ? "Showing " + (currentPage === 0 || paginationRange.length < 2
    ? ((props.totalCount - props.currentTable) + 1) + " - " + props.totalCount
    : ((currentTable * (currentPage - 1) + pageSize) - (currentTable - 1)) + " - " +
    (currentTable * (currentPage - 1) + pageSize))
    + " of "
    + props.totalCount
    + " records"
    : "";

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const selectPage = (rangeMessage) => {
    return (
      <>
        {rangeMessage.length > 0 &&
          <div className={style.paginationitem}>
            {rangeMessage}
            <select key={props.pageSize} className={style.paginationselect}
              value={props.pageSize}
              onChange={e => {
                { props.setPageSize(Number(e.target.value)) }
                { props.setCurrentPage(1) }
                { props.setSearchValues(props.initialSearchValues) }
              }}
            >

              {[10, 20, 30, 40, 50, 100].map((pageSize, index) => (
                <option key={index} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        }
      </>
    )
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return (selectPage(rangeMessage));
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={style.paginationdiv}>
      <ul
        className={style.paginationcontainer}
      >
        <li className={style.paginationitem}>
          <button
            onClick={onPrevious}
            disabled={currentPage == 1 ? true : false}
          >
            <div className={style.arrowleft} />
          </button>
        </li>


        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li key={index} className={style.dots}>&#8230;</li>;
          }

          return (
            <div key={index}>
              <li
                className={`${style.paginationitem} ${pageNumber === currentPage ? style.selected : style.paginationitem}  `}
                onClick={() => (onPageChange(pageNumber))}
              >
                {pageNumber}
              </li>

            </div>

          );

        })}
        <li className={style.paginationitem} >
          <button
            onClick={onNext}
            disabled={currentPage == lastPage ? true : false}
          >
            <div className={style.arrowright} />
          </button>
        </li>
      </ul>
      <div className={style.paginationitem}>
        {selectPage(rangeMessage)}

      </div>
    </div>
  );
};

export default Pagination;
