import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from '../../css/toast.module.css';
import 'font-awesome/css/font-awesome.min.css';

const Toast = props => {
  
  const { toastList, position, autoDelete, autoDeleteTime } = props;
  const [list, setList] = useState(toastList);


  useEffect(() => {
      setList([...toastList]);
    
  }, [toastList]);

  useEffect(() => {
      const interval = setInterval(() => {
          if (autoDelete && toastList.length && list.length) {
              deleteToast(toastList[0].id);
          }
      }, autoDeleteTime);
      
      return () => {
          clearInterval(interval);
      }

  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteToast = id => {
      const listItemIndex = list.findIndex(e => e.id === id);
      const toastListItem = toastList.findIndex(e => e.id === id);
      list.splice(listItemIndex, 1);
      toastList.splice(toastListItem, 1);
      setList([...list]);

    }


    

return (
        <>
            <div className={`${style.notificationcontainer} ${style.bottomleft}`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`${style.notification} ${style.toast} ${style.bottomleft}`}
                            style={{ backgroundColor: toast[i].backgroundColor }}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            <div className="flex">
                                   <div className="grid grid-cols-7 grid-rows-1">    
                                           <div className="col-span-1 py-0">
                                                <div className={`${style.notificationimage}`}>
                                                   <i className="fa-solid fa-check-circle fa-2x "></i>
                                                 </div>
                                            </div>    

                                            <div className="col-span-6 py-1">
                                               <span className={`${style.notificationmessage}`}>
                                                     {toast[i].description}
                                               </span>
                                             </div>    
                                    </div>    
                             </div>

                            
                        </div>
                    )
                }
            </div>
        </>
    );}

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  autoDelete: PropTypes.bool,
  autoDeleteTime: PropTypes.number
}


export default Toast;
