const clientId =
    process.env.REACT_APP_CLIENTID === undefined
        ? window.ssoClientId
        : process.env.REACT_APP_CLIENTID;
const tenantId =
    process.env.REACT_APP_TENANTID === undefined
        ? window.ssoTenantId
        : process.env.REACT_APP_TENANTID;

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
        clientId: clientId,
        // Full directory URL, in the form of https://login.microsoftonline.com/e06c7219-8392-41e0-b5f8-01bceb0949b0
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: process.env.REACT_APP_REDIRECTURI,
        knownAuthorities: [process.env.REACT_APP_KNOWNAUTHORITIES],
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        windowHashTimeout: 6000, //Timeout in milliseconds to wait for popup operations to resolve.
        loadFrameTimeout: 6000, //Timeout in milliseconds to wait for iframe/popup operations resolve.
        asyncPopups: true, //Sets whether popups are opened asynchronously
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
