import React from 'react';
import { NavLink } from 'react-router-dom';
import style from '../../css/navItem.module.css';

const NavItem = props => {
  const { label, Icon, to, children, disableOption } = props.item;

  if (disableOption == 'true') {

    return (
      <label className={style.navLabelDisabled} >{label}
      </label>
    );
  }

  return (
    <NavLink
      to={to}
      className={style.navItem}

    >

      <span className={style.navLabel} >{label}</span>
    </NavLink>
  );
};




export default NavItem;
