import {
  HomeIcon,
  UserIcon,
  CogIcon,
  UserCircleIcon,
  ShieldCheckIcon,
  LockOpenIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline';

export const sideMenu = [
  {
    label: 'Agency Maintenance',
    Icon: 'UserIcon',
    to: '/agency',
    disableOption:'false',
  },
  {
    label: 'Translations:',
    Icon: '',
    to: '/',
    disableOption:'true',
  },
  {
    label: 'County ID',
    Icon: '',
    to: '/county',
    disableOption:'false',
  },
  {
    label: 'Entity',
    Icon: '',
    to: '/entity',
    disableOption:'false',
  },
  {
    label: 'CLLI',
    Icon: '',
    to: '/clli',
    disableOption:'false',
  },
  {
    label: 'Exchange',
    Icon: '',
    to: '/exchange',
    disableOption:'false',
  }



];
