import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL + "/entityexchange";

class ExchangeService {

    createExchange(exchange){
        return axios.post(baseURL + "/save", exchange);
    }

    getExchangeById(exchangeId){
        return axios.get(baseURL + '/exchange/search?idNum=' + exchangeId);
    }    

    updateExchange(exchange){
        return axios.post(baseURL + "/exchange", exchange);
    }

    deleteExchange(exchangeId){
        return axios.post(baseURL + '/exchange/delete?exchange=' + exchangeId);
    }

    deleteExchangeById(idNum){
        return axios.post(baseURL + '/exchange/delete?idNum=' + idNum);
    }

    deleteBatchExchange(exchange){
        return axios.post(baseURL + '/deleteall',  exchange);
    }

    
}

export default new ExchangeService()