import React, {useEffect, useState} from 'react';
import { useTable, useSortBy, useBlockLayout} from "react-table"
import style from '../../css/layout.module.css';


const EditableCell = ({
  cell: { value: initialValue }, 
  row: { index },
  column: { id },
  setErrorMessage,
  updateData,
  column: {Header: header},
  columnMaxLength,
}) => {


  const [value, setValue] = useState(initialValue);
  const [headerValue, setHeaderValue] = useState(header);
  const [columnId, setColumnId] = useState(id);
  const [maxLength, setMaxLength] = useState(10);

  const handleChange = e => {
    setValue(e.target.value.toUpperCase());
  };

const handleBlur = () => {
    setErrorMessage("");
    if (!value) {
        setErrorMessage("Please enter a valid " + headerValue);
        setValue(initialValue);
    }
    updateData(index, id, value)
}; 



useEffect(() => {
    setValue(initialValue);
    if(columnMaxLength){
      setMaxLength(columnMaxLength[columnId]);
    }

}, [initialValue,columnMaxLength]);

   return <input value={value} maxLength={maxLength} 
   style={{width:"150px","textAlign": "left"}} 
   onChange={handleChange} onBlur={handleBlur} />;
};


const defaultColumn = {
  Cell: EditableCell
};

const EditableTable = ({ columns, 
                         data, 
                         updateData,
                         initialState,  
                         setErrorMessage, 
                         errorMessage, 
                         handleNewRowClick, 
                         tableTitle, 
                         setButtonDisabled,
                         columnMaxLength
}) => {


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState:initialState,
      defaultColumn, 
      updateData,
      setErrorMessage,
      handleNewRowClick,
      autoResetSortBy: false,
      tableTitle,
      setButtonDisabled,
      columnMaxLength,
    },
    useSortBy,
    useBlockLayout,
  );

  return (
    <>
    <div >
    <div className="col-span-2 text-left px-4" ><h3 className={style.h1text}>{tableTitle}</h3></div>    
    <div className="flex flex-col py-2"> 
    <table  {...getTableProps()} >
    <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th 
                    className="py-2 text-left text-xs font-medium text-gray-1000"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column.sortable?column.toggleSortBy(!column.isSortedDesc):null}
                 >              
                  {column.render("Header")}
                  <span className={style.requiredLabel}>
                    {column.required 
                        ? "*"
                        : ""}
                  </span>
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}

        </thead>
        

        <tbody className="bg-white text-left" {...getTableBodyProps()}>
          {rows.map(
            (row, i) =>
             prepareRow(row) || (
             <tr {...row.getRowProps()}
                className="text-xs text-left px-1 py-2 whitespace-nowrap"
                >
                {row.cells.map((cell) => {
                  return (
                    <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                
              </tr>
            )
        )}
      </tbody>
        <tfoot>
         </tfoot>
             </table>
             <div className="py-3">
                <button className={style.addtext} onClick={handleNewRowClick} >+</button>
             </div>
             <div className={style.centered}>    
             {errorMessage && (
                            <div className={style.showError}>
                                {errorMessage}
                            </div>
                        )}
             </div>
       </div>      
   </div>    
    </>
  );
};

export default EditableTable;
