import React, {useEffect, useState,useRef} from 'react';
import axios from "axios";
import blank from '../../images/blank.GIF'
import { Col } from 'react-bootstrap'
import "@fortawesome/fontawesome-free/css/all.css";


const Translations = props => {

    useEffect(() => {
    }, 
    [props.elements,props.setElements]);

    useEffect(() => {
        {props.setAllSelected(props.allSelected)}
    }, 
    [props.allSelected]);

    useEffect(() => {
        const newPage = props.PageSize;
        {props.setPageSize(newPage)}
    }, 
    [props.PageSize]);

    useEffect(() => {
      const newData = props.data;
      {props.setData(newData)}
    }, 
    [props.data, props.setData]);

    useEffect(() => {
        const onRowCheck = (rowChecked) =>{
    
          let tempList = props.dataList;
    
          if(!rowChecked.checked){
            const newList = tempList.filter((row) => row !== rowChecked.idNum);
            {props.setDataList(newList)}
          }    
          else{
            tempList = [...tempList,rowChecked.idNum];
            {props.setDataList(tempList)}
          }
       }
    
       onRowCheck(props.rowChecked);
    }, 
    [props.rowChecked]);

     useEffect(() => {
        {props.setShowEditModal(props.showEditModal)}    
        {props.setRowId(props.rowId)}
      }, 
      []);  
      
      useEffect(() => {
        {props.setShowDeleteModal(props.showDeleteModal)}    
        {props.setRowId(props.rowId)}
      }, 
      []);  

      useEffect(() => {
        {props.setShowEditTableModal(props.showEditTableModal)}    
      }, 
      []);  

      useEffect(() => {
        {props.setShowModal(props.showModal)}    
      }, 
      []);  
      
     useEffect(() => {
        {props.setAddType(props.addType)}    
     }, 
     [props.addType]);  

}    

export function InitialValues(initialSearchValues, url, columns){


    const initialCheckValues =  {
        isChecked:false,
        IdNum:'',
    }

    const initialState = {
        hiddenColumns: ['idNum']
    };
    
    const [rowId, setRowId] = React.useState('');
    const [PageSize, setPageSize] = useState(30);
    const [data, setData] = useState([]);
    const [optionsStateValue, setOptionsStateValue] = React.useState([]);
    const [optionsCountyValue, setOptionsCountyValue] = React.useState([]);    
    const [searchValues, setSearchValues] = useState(initialSearchValues);
    const [dataList, setDataList] = useState([]);
    const [deleteType, setDeleteType] = useState("");
    const [elements,setElements] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [sorting, setSorting] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const selectTableRef = useRef(null);
    const selectInputCountyRef = useRef();
    const selectInputStateRef = useRef(null);
    const selectAllRef = useRef();
    const [errorMessage, setErrorMessage]= useState("");
    const [showEditModal, setShowEditModal] = useState(false);    
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditTableModal, setShowEditTableModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [agencyId, setAgencyId] = useState('');
    const [clliCd, setClliCd] = useState('');
    const [customerId, setCustomerId] = useState('');

    const [addType, setAddType] = useState('');    
    const [tableData, setTableData] = useState([]);

    const [rowChecked, setRowChecked] = useState(initialCheckValues);
    const entityRef = useRef();
    const baseURL = url;


    const showImage = (dataTable) =>  {
        return dataTable.length===0 
        ? <Col sm={2} className="m-auto">
          <img
             className="py-5 d-block mx-auto img-fluid w-90"
             src={blank}
             alt=""/>
         </Col>      
        : null
    
    };
    
    const onClickEditModal = (rowIdx) =>{
        setShowEditModal(true);
        setRowId(rowIdx);
    }

    const onClickDeleteModal = (rowIdx, type, agencyId, customerId) =>{
        setShowDeleteModal(true);
        setRowId(rowIdx);
        setDeleteType(type);
        setAgencyId(agencyId);  
        setCustomerId(customerId);
    }

    const onClickTableEditModal = (dataList) =>{
        setShowEditTableModal(true);
    }
        
    const onClickDeleteAgencyModal = (rowIdx, agencyId, customerId) =>{
      setShowDeleteAgencyModal(true);
      setRowId(rowIdx);
      setAgencyId(agencyId);
      setCustomerId(customerId);
    }
    
    const onClickModal = () =>{
      setShowModal(true);
    }

    const onCloseModal = () =>{
      setShowModal(false);
    }

    const onClickAddModal = () =>{
      {setShowAddModal(true)}
    }

    const onCloseAddModal = () =>{
      setShowAddModal(false);
    }

    const onCloseEditModal = () =>{
      setShowEditModal(false);
    }

    const onCloseDeleteModal = () =>{
      setShowDeleteModal(false);
      setDeleteType("");
    }

    const getDataWithAxios = async (baseURL) => {
        axios.get(baseURL).then((response) => {
          setData(response.data);
        });
    };

    const handleOnChange = (e,idNum,selectAll) => {

        if(selectAllRef.current){
          if(selectAllRef.current.checked){
            if(selectAll===null)
            {
              selectAllRef.current.checked=false;
            }
            
          }
        }
    
        setRowChecked({'checked':e.target.checked,'idNum':idNum});         
        setAllSelected(false);
    
        var elementArray = elements;
        var index = elementArray.indexOf(idNum);
    
          if(selectAll!==null){
            if(e.target.checked){
              elementArray.push(idNum);
              setElements(elementArray);
            }
            else{
              elementArray.splice(0, elements.length);
              setElements(elementArray);
            }
          }
          else{
    
            if(selectAllRef.current){
              if(selectAllRef.current.checked){
                selectAllRef.current.checked=false;
              }
            }
      
            index = elementArray.indexOf(idNum);
            if (index > -1) {
              elementArray.splice(index, 1);
              setElements(elementArray);
            }  else{
              elementArray.push(idNum);
              setElements(elementArray);
            }
        }
     }
        
    const handleOnChangeAll = (props,event) => {

        var checkedCopy = [];
    
        setAllSelected(event.target.checked);
    
        if(event){
          if(event.target.checked){
            props.data.forEach(function(e, index) {
              checkedCopy.push(e.idNum);
              handleOnChange(event,e.idNum,true);
              setRowChecked({'checked':event.target.checked,'idNum':e.idNum});
            });
            setElements(checkedCopy);
            setDataList(checkedCopy);
          }
          else{
            props.data.forEach(function(e, index) {
              handleOnChange(event,e.idNum,true);
              setRowChecked({'checked':event.target.checked,'idNum':e.idNum});
            });
            setElements(checkedCopy);
            setDataList(checkedCopy);
          }
           
        }
    };
    
    function isNetworkError(err) {
        return !!err.isAxiosError && !err.response;
    }  
   
    return {
        rowId,
        setRowId,
        PageSize,
        setPageSize,
        data,
        setData,
        optionsStateValue,
        setOptionsStateValue,
        optionsCountyValue,
        setOptionsCountyValue,
        searchValues,
        setSearchValues,
        dataList,
        setDataList,
        deleteType,
        setDeleteType,
        elements,
        setElements,
        allSelected,
        setAllSelected,
        sorting,
        setSorting,
        currentPage,
        setCurrentPage,
        selectTableRef,
        selectInputCountyRef,
        selectInputStateRef,
        selectAllRef,
        errorMessage, 
        setErrorMessage,
        showEditModal, 
        setShowEditModal,
        showAddModal, 
        setShowAddModal,
        showDeleteModal, 
        setShowDeleteModal,
        showEditTableModal, 
        setShowEditTableModal,
        rowChecked, 
        setRowChecked,
        entityRef,
        baseURL,
        isNetworkError,
        initialState,
        showImage,
        onClickEditModal,
        onClickDeleteModal,
        onClickTableEditModal,
        getDataWithAxios,
        handleOnChange,
        handleOnChangeAll,
        onClickDeleteAgencyModal,
        agencyId, 
        setAgencyId,
        clliCd,
        onClickModal,
        onCloseModal,
        onClickAddModal,
        onCloseAddModal,
        onCloseEditModal,
        onCloseDeleteModal,
        showModal,
        setShowModal,
        addType,
        setAddType,
        tableData, 
        setTableData,
        columns
    };    

}

export default Translations;