import './App.css';
import "./fonts/Metropolis-Thin.otf";
import "./fonts/Metropolis-Light.otf";
import "./fonts/Metropolis-SemiBold.otf";
import "./fonts/Metropolis-Regular.otf";
import "./fonts/Metropolis-Black.otf";
import "./fonts/Metropolis-Medium.otf";
import "./fonts/Metropolis-Bold.otf";

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout.js';
import GeneralView from './components/views/GeneralView.js';

import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType, BrowserUtils } from '@azure/msal-browser'
import { loginRequest } from "./authConfig"
import Grid from "@mui/material/Grid";
import { Col, Row, Result } from 'antd'

function App(msalInstance) {

  const { instance } = msalInstance;

  console.log("Msal instance...");
  console.log(instance.getActiveAccount());

  return (

    //This component will render its children if a user is authenticated or attempt to sign a user in. 
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={LoadingComponent}>

      <Router>
        <div className="App">
          <Layout instance={instance} signOutClickHandler={signOutClickHandler}>
            <Pages />
          </Layout>
        </div>
      </Router>

    </MsalAuthenticationTemplate>
  )
}

function signOutClickHandler(instance) {
  const logoutRequest = {
    account: instance.getActiveAccount(),
    onRedirectNavigate: () => !BrowserUtils.isInIframe()
  };

  instance.logoutRedirect(logoutRequest);
}

function LoadingComponent() {
  return <Grid container justifyContent="center">
    <p>Authentication in progress...</p>
  </Grid>;
}

function Pages() {
  return (
    <Routes>
      <Route path="/*" />
      <Route index element={<GeneralView entityViewType={"agency"} />} />
      <Route path="/County" element={<GeneralView entityViewType={"county"} />} />
      <Route path="/Entity" element={<GeneralView entityViewType={"entity"} />} />
      <Route path="/Exchange" element={<GeneralView entityViewType={"exchange"} />} />
      <Route path="/Clli" element={<GeneralView entityViewType={"clli"} />} />
      <Route path="/Agency" element={<GeneralView entityViewType={"agency"} />} />
      <Route path="*" element={<NonFoundPage />} />
    </Routes>
  )
}

// Catch-all route for 404 errors
function NonFoundPage() {
  return (
    <Row justify="center">
      <Col>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </Col>
    </Row>
  )
}
export default App;
