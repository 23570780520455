import React, { useEffect } from 'react';
import style from '../../css/layout.module.css';


const TableGeneralSearch = ({ searchValues, setSearchValues,
  key1, keyName1, placeHolder1,
  key2, keyName2, placeHolder2,
  key3, keyName3, placeHolder3,
  key4, keyName4, placeHolder4,
  key5, keyName5, placeHolder5,
  key6, keyName6, placeHolder6,
  initialSearchValues,
  focus, setFocus,
  key1Ref,
  key2Ref,
  key3Ref,
  key4Ref,
  key5Ref,
  key6Ref,
  clearGlobalSearch,
  globalSearchMulti,
  entityType,
  setCurrentData,
  setCurrentPage
}) => {

  useEffect(() => {
    if (focus.current) {
      focus.current.focus();
    }


  }, [focus.current]);


  return (


    <form className={style.SearchBarStyle}>
      <input
        type="search"
        name={keyName1}
        value={key1}
        ref={key1Ref}
        onChange={e => {
          setFocus(key1Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues, setCurrentPage);
          clearGlobalSearch();

        }}
        onFocus={(e) => e.persist()}
        placeholder={placeHolder1}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />

      {key2 !== null && <input
        type="search"
        name={keyName2}
        value={key2}
        ref={key2Ref}
        onChange={e => {
          setFocus(key2Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues, setCurrentPage);
          clearGlobalSearch();
        }}
        placeholder={placeHolder2}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />
      }

      {key3 !== null && <input
        type="search"
        name={keyName3}
        value={key3}
        ref={key3Ref}
        onChange={e => {
          setFocus(key3Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues, setCurrentPage);
          clearGlobalSearch();
        }}
        placeholder={placeHolder3}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />
      }
      {key4 !== null && <input
        type="search"
        name={keyName4}
        value={key4}
        ref={key4Ref}
        onChange={e => {
          setFocus(key4Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues, setCurrentPage);
          clearGlobalSearch();
        }}
        placeholder={placeHolder4}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />
      }
      {key5 !== null && <input
        type="search"
        name={keyName5}
        value={key5}
        ref={key5Ref}
        onChange={e => {
          setFocus(key5Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues, setCurrentPage);
          clearGlobalSearch();
        }}
        placeholder={placeHolder5}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />
      }
      {key6 !== null && <input
        type="search"
        name={keyName6}
        value={key6}
        ref={key6Ref}
        onChange={e => {
          setFocus(key6Ref);
          globalSearchMulti(e, entityType, { ...searchValues, [e.target.name]: e.target.value }, setCurrentData, setSearchValues), setCurrentPage;
          clearGlobalSearch();
        }}
        placeholder={placeHolder6}
        placeholdertextcolor="#000"
        aria-label="Search"
        aria-describedby="button-addon1"
      />
      }
    </form>

  )

};

export default TableGeneralSearch;