import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL + "/county";

class CountyService {

    createCounty(county){
        return axios.post(baseURL + "/save", county);
    }

    getCountyById(countyId){
        return axios.get(baseURL + '/search?idNum=' + countyId);
    }    

    updateCounty(county){
        return axios.post(baseURL, county);
    }

    deleteCounty(countyId){
        return axios.post(baseURL + '/delete?countyCd=' + countyId);
    }

    deleteCountybyId(countyId){
        return axios.post(baseURL + '/delete?idNum=' + countyId);
    }

    deleteBatchCounty(county){
        return axios.post(baseURL + '/deleteall',  county);
    }
    
}

export default new CountyService()