import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../css/layout.module.css';
import {FaCheck } from "react-icons/fa";
import ReactSwitch from 'react-switch';
import modalStyle from '../../css/globaltable.module.css';

import AgencyService from "../services/AgencyService";
import CountyService from "../services/CountyService";
import ClliService from "../services/ClliService";
import EntityService from "../services/EntityService";
import ExchangeService from "../services/ExchangeService";

class CreateModal extends Component {

    constructor(props) {
        super(props)


        this.state = {
            id: '',
            idNum: '',
            customerId: '',
            stateCd: '',
            countyName: '',
            countyId:'',
            community:'',
            entity:'',
            clliCd:'',
            exchange:'',
            agencyId:'',
            agencyName:'',
            inputMsagAddrPtFileLoc:'',
            outputMsagChgFileLoc:'',
            outputFileArchiveLoc:'',
            outputLogFileLoc:'',
            consolidateStreetRanges:false,
            formErrors: {},
            inputClass:'',
            message:'',
            buttonLabel:'',
            successMessage:'',
        }

        this.initialState = this.state;

        this.changeAgencyIdHandler = this.changeAgencyIdHandler.bind(this);
        this.changeCustomerIdHandler = this.changeCustomerIdHandler.bind(this);
        this.changeAgencyNameHandler = this.changeAgencyNameHandler.bind(this);
        this.changeStateCdHandler = this.changeStateCdHandler.bind(this);
        this.changeCountyNameHandler = this.changeCountyNameHandler.bind(this);
        this.changeCountyIdHandler = this.changeCountyIdHandler.bind(this);
        this.changeCommunityHandler = this.changeCommunityHandler.bind(this);
        this.changeEntityHandler = this.changeEntityHandler.bind(this);
        this.changeClliCdHandler = this.changeClliCdHandler.bind(this);
        this.changeExchangeHandler = this.changeExchangeHandler.bind(this);
        this.changeInputMsagAddrPtFileLocHandler = this.changeInputMsagAddrPtFileLocHandler.bind(this);
        this.changeOutputMsagChgFileLocHandler = this.changeOutputMsagChgFileLocHandler.bind(this);
        this.changeOutputLogFileLocHandler = this.changeOutputLogFileLocHandler.bind(this);
        this.changeOutputFileArchiveLocHandler = this.changeOutputFileArchiveLocHandler.bind(this);
        this.changeConsolidateStreetRangesHandler = this.changeConsolidateStreetRangesHandler.bind(this);

        this.saveOrUpdateAgency = this.saveOrUpdateAgency.bind(this);
        this.saveOrUpdateCounty = this.saveOrUpdateCounty.bind(this);
        this.saveOrUpdateClli = this.saveOrUpdateClli.bind(this);
        this.saveOrUpdateEntity = this.saveOrUpdateEntity.bind(this);
        this.saveOrUpdateExchange = this.saveOrUpdateExchange.bind(this);

        this.saveOrUpdateGeneral = this.saveOrUpdateGeneral.bind(this);
        this.updateResponse = this.updateResponse.bind(this);

    }

    componentDidMount(prevProps, prevState){
        if(this.props.addIdType === '_add'){
            this.setState(this.initialState);
            this.state.buttonLabel='Add';
            return;
        }
        if(this.props.editIdType === '_edit'){

            this.state.buttonLabel='Save';
            

            switch (this.props.entityType) {

                case "agency":   
                    AgencyService.getAgencyById(this.props.rowId).then( (res) =>{
                        let agency = res.data[0];
            
                        this.setState(
                        {
                            customerId: agency.customerId,
                            agencyId: agency.agencyId,
                            agencyName: agency.agencyName,
                            inputMsagAddrPtFileLoc: agency.inputMsagAddrPtFileLoc,
                            outputMsagChgFileLoc: agency.outputMsagChgFileLoc,
                            outputFileArchiveLoc: agency.outputFileArchiveLoc,
                            outputLogFileLoc: agency.outputLogFileLoc,
                            consolidateStreetRanges: agency.consolidateStreetRanges==1?true:false,
                        }
                        );
                    });
                    return;

                case "county":
                    CountyService.getCountyById(this.props.rowId).then( (res) =>{
                        let county = res.data[0];
            
                         this.setState(
                         {
                            idNum:county.idNum,
                            customerId: county.customerId,
                            stateCd:county.stateCd,
                            countyName:county.countyName,
                            countyId:county.countyCd
                        }
                        );
                    });
                    return; 

                case "clli":
                    ClliService.getClliById(this.props.rowId).then( (res) =>{
                        let clli = res.data[0];
            
                        this.setState(
                        {
                            customerId: clli.customerId,
                            stateCd:clli.stateCd,
                            countyName:clli.countyName,
                            entity:clli.entity,
                            clliCd:clli.clliCd,
                            community:clli.community
                        }
                        );
                    });
                    return; 

                case "entity":
                    EntityService.getEntityById(this.props.rowId).then( (res) =>{
                        let entity = res.data[0];
            
                         this.setState(
                         {
                            idNum:entity.idNum,
                            customerId: entity.customerId,
                            stateCd:entity.stateCd,
                            countyName:entity.countyName,
                            enity:entity.entity,
                            entity:entity.entity,
                            community:entity.community
                        }
                        );
                    });
                    return; 

                case "exchange":
                    ExchangeService.getExchangeById(this.props.rowId).then( (res) =>{
                        let exchange = res.data[0];
            
                         this.setState(
                         {
                            customerId: exchange.customerId,
                            stateCd:exchange.stateCd,
                            countyName:exchange.countyName,
                            exchange:exchange.exchange,
                            community:exchange.community
                        }
                        );
                    });
                    return; 
            }    
        }
    }

    saveOrUpdateGeneral = (e) => {

        switch (this.props.entityType) {

            case "agency":   
               this.saveOrUpdateAgency(e);
               return;
    
            case "county":   
               this.saveOrUpdateCounty(e);
               return;

            case "clli":   
               this.saveOrUpdateClli(e);
               return;

            case "entity":   
               this.saveOrUpdateEntity(e);
               return;

            case "exchange":   
               this.saveOrUpdateExchange(e);
               return;
               
        }    
    
        
        


    }    

     saveOrUpdateAgency = (e) => {

        if (this.handleAgencyFormValidation()){      

        let agencyCreate = {customerId: this.state.customerId.trim(),
                      agencyId: this.state.agencyId.trim(),
                      agencyName: this.state.agencyName.trim(),
                      inputMsagAddrPtFileLoc: this.state.inputMsagAddrPtFileLoc.trim(),
                      outputMsagChgFileLoc:this.state.outputMsagChgFileLoc.trim(),
                      outputFileArchiveLoc: this.state.outputFileArchiveLoc.trim(),
                      outputLogFileLoc: this.state.outputLogFileLoc.trim(),
                      consolidateStreetRanges: this.state.consolidateStreetRanges?1:0,
                      createUser: window.name.match(/^([^@]*)@/)[1]
                     };
        
        if(this.props.addIdType === '_add'){
            AgencyService.createAgency(agencyCreate)
            .then(res =>{
                this.updateResponse(res);
             })
            .catch(err =>{
                this.setState({message:err.message});
            });

         }else{

            let agencyModify = {customerId: this.state.customerId,
                            agencyId: this.state.agencyId,
                            agencyName: this.state.agencyName,
                            inputMsagAddrPtFileLoc: this.state.inputMsagAddrPtFileLoc,
                            outputMsagChgFileLoc:this.state.outputMsagChgFileLoc,
                            outputFileArchiveLoc: this.state.outputFileArchiveLoc,
                            outputLogFileLoc: this.state.outputLogFileLoc,
                            consolidateStreetRanges: this.state.consolidateStreetRanges?1:0,
                            modifyUser: window.name.match(/^([^@]*)@/)[1]
                        };

            AgencyService.updateAgency(agencyModify)
            .then(res =>{
                {this.props.getDataWithAxios(this.props.baseURL)}
                this.cancel();
                this.props.setSearchValues(this.props.initialSearchValues);
                {this.props.showToast("Entry Updated")}
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }
    }
    }

    saveOrUpdateCounty = (e) => {

        if (this.handleCountyFormValidation()){        
            let arrayCounty = [];
            let countyCreate = {idNum:this.state.idNum,
                        customerId: this.state.customerId.trim(),
                        countyCd:this.state.countyId.trim(),
                        stateCd: this.state.stateCd.trim(),
                        countyName: this.state.countyName.trim(), 
                        createUser: window.name.match(/^([^@]*)@/)[1] 
                        };
            arrayCounty.push(countyCreate);             
        
            if(this.props.addIdType === '_add'){

                CountyService.createCounty(arrayCounty)
                .then(res =>{
                    this.updateResponse(res);
                })
                .catch(err =>{
                    this.setState({message:err.message});
                });

        }else{

            let countyModify = {idNum: this.state.idNum,
                          customerId: this.state.customerId,
                          countyCd:this.state.countyId,
                          stateCd: this.state.stateCd,
                          countyName: this.state.countyName, 
                          modifyUser: window.name.match(/^([^@]*)@/)[1] 
                         };

            CountyService.updateCounty(countyModify)
            .then(res =>{
                {this.props.getDataWithAxios(this.props.baseURL)}
                this.cancel();
                this.props.setSearchValues(this.props.initialSearchValues);
                this.props.setOptionsStateValue([]);
                this.props.setOptionsCountyValue([]);
                {this.props.showToast("Entry Updated")}
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }
    }
    }

    updateResponse(res){

        if(res.data.statusCode == "FOUND"){
            this.setState({message:res.data.body});    
        }
        if(res.data.statusCode == "CREATED"){
            {this.props.getDataWithAxios(this.props.baseURL)}
            this.cancel();
            this.props.setSearchValues(this.props.initialSearchValues);
            this.props.setOptionsStateValue([]);
            this.props.setOptionsCountyValue([]);
            {this.props.showToast("New entry added")}

        }

    }

    saveOrUpdateClli = (e) => {

        if (this.handleClliFormValidation()){        
        let arrayClli = [];
        let clliCreate = {customerId: this.state.customerId.trim(),
                      community:this.state.community.trim(),
                      stateCd: this.state.stateCd.trim(),
                      countyName: this.state.countyName.trim(), 
                      entity:this.state.entity.trim(),
                      clliCd:this.state.clliCd.trim(), 
                      createUser: window.name.match(/^([^@]*)@/)[1]
                     };
        arrayClli.push(clliCreate);             
        
        if(this.props.addIdType === '_add'){

            ClliService.createClli(arrayClli)
            .then(res => {
                this.updateResponse(res);
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }else{

            let clliModify = {customerId: this.state.customerId.trim(),
                          clliCd: this.state.clliCd.trim(),
                          community:this.state.community.trim(),
                          stateCd: this.state.stateCd.trim(),
                          countyName: this.state.countyName.trim(), 
                          entity:this.state.entity.trim(),
                          modifyUser: window.name.match(/^([^@]*)@/)[1] 
                         };

            ClliService.updateClli(clliModify)
            .then(res =>{
                {this.props.getDataWithAxios(this.props.baseURL)}
                this.cancel();
                this.props.setSearchValues(this.props.initialSearchValues);
                this.props.setOptionsStateValue([]);
                this.props.setOptionsCountyValue([]);
                {this.props.showToast("Entry Updated")}
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }
    }
    }

    saveOrUpdateEntity = (e) => {

        if (this.handleEntityFormValidation()){        
        let arrayEntity = [];
        let entityCreate = {idNum:this.state.idNum,
                      customerId: this.state.customerId.trim(),
                      community:this.state.community.trim(),
                      stateCd: this.state.stateCd.trim(),
                      countyName: this.state.countyName.trim(), 
                      entity:this.state.entity.trim(), 
                      createUser: window.name.match(/^([^@]*)@/)[1] 
                     };
        arrayEntity.push(entityCreate);             
        
        if(this.props.addIdType === '_add'){

            EntityService.createEntity(arrayEntity)
            .then(res =>{
                this.updateResponse(res);
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }else{

            let entityModify = {idNum:this.state.idNum,
                          customerId: this.state.customerId.trim(),
                          entity: this.state.entity.trim(),
                          community:this.state.community.trim(),
                          stateCd: this.state.stateCd.trim(),
                          countyName: this.state.countyName.trim(), 
                          modifyUser: window.name.match(/^([^@]*)@/)[1] 
                         };

            EntityService.updateEntity(entityModify)
            .then(res =>{
                {this.props.getDataWithAxios(this.props.baseURL)}
                this.cancel();
                this.props.setSearchValues(this.props.initialSearchValues);
                this.props.setOptionsStateValue([]);
                this.props.setOptionsCountyValue([]);
                {this.props.showToast("Entry Updated")}
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }
    }
    }

    saveOrUpdateExchange = (e) => {

      if (this.handleExchangeFormValidation()){        
        let arrayExchange = [];
        let exchangeCreate = {customerId: this.state.customerId.trim(),
                      community:this.state.community.trim(),
                      stateCd: this.state.stateCd.trim(),
                      countyName: this.state.countyName.trim(), 
                      exchange:this.state.exchange.trim(), 
                      createUser: window.name.match(/^([^@]*)@/)[1] 
                     };
        arrayExchange.push(exchangeCreate);             
        
       if(this.props.addIdType === '_add'){

            ExchangeService.createExchange(arrayExchange)
            .then(res =>{
                this.updateResponse(res);
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }else{

            let exchangeModify = {customerId: this.state.customerId,
                          exchange: this.state.exchange.trim(),
                          community:this.state.community.trim(),
                          stateCd: this.state.stateCd.trim(),
                          countyName: this.state.countyName.trim(), 
                          modifyUser: window.name.match(/^([^@]*)@/)[1] 
                         };

            ExchangeService.updateExchange(exchangeModify)
            .then(res =>{
                {this.props.getDataWithAxios(this.props.baseURL)}
                this.cancel();
                this.props.setSearchValues(this.props.initialSearchValues);
                this.props.setOptionsStateValue([]);
                this.props.setOptionsCountyValue([]);
                {this.props.showToast("Entry Updated")}
            })
            .catch(err =>{
                this.setState({message:err.message});
            });

        }
    }
    }

    changeAgencyIdHandler= (event) => {
        this.setState({agencyId: event.target.value});
    }
    
    changeCustomerIdHandler= (event) => {
        this.setState({customerId: event.target.value.toUpperCase()});
    }

    changeAgencyNameHandler= (event) => {
        this.setState({agencyName: event.target.value});
    }

    changeInputMsagAddrPtFileLocHandler= (event) => {

        this.setState({inputMsagAddrPtFileLoc: event.target.value.replace('\\n', '\n')});
    }

    changeOutputMsagChgFileLocHandler= (event) => {
        this.setState({outputMsagChgFileLoc: event.target.value.replace('\\n', '\n')});
    }

    changeOutputLogFileLocHandler= (event) => {
        this.setState({outputLogFileLoc: event.target.value.replace('\\n', '\n')});
    }

    changeOutputFileArchiveLocHandler= (event) => {
        this.setState({outputFileArchiveLoc: event.target.value.replace('\\n', '\n')});
    }

    changeConsolidateStreetRangesHandler= (event) => {
        this.setState({consolidateStreetRanges: event});
    }
    
    changeStateCdHandler= (event) => {
        this.setState({stateCd: event.target.value.toUpperCase()});
    }

    changeCountyNameHandler= (event) => {
        this.setState({countyName: event.target.value.toUpperCase()});
    }

    changeCountyIdHandler= (event) => {
        this.setState({countyId: event.target.value.toUpperCase()});
    }
    
    changeCommunityHandler= (event) => {
        this.setState({community: event.target.value.toUpperCase()});
    }

    changeEntityHandler= (event) => {
        this.setState({entity: event.target.value.toUpperCase()});
    }

    changeClliCdHandler= (event) => {
        this.setState({clliCd: event.target.value.toUpperCase()});
    }

    changeExchangeHandler= (event) => {
        this.setState({exchange: event.target.value.toUpperCase()});
    }

    handleAgencyFormValidation() {
        const { customerId, agencyId } = this.state;
        let formErrors = {};
        let formIsValid = true;
        
        this.setState({successMessage:''});

        if (!customerId.trim()) {
          formIsValid = false;
          formErrors["customerIdErr"] = "Please enter a valid Customer ID";
        }
        if (!agencyId.trim()) {
            formIsValid = false;
            formErrors["agencyIdErr"] = "Please enter a valid Agency ID";
        }
        this.setState({ formErrors: formErrors });
        return formIsValid;
      }
    

    handleCountyFormValidation() {
        const { customerId, stateCd, countyName, countyId } = this.state;
        let formErrors = {};
        let formIsValid = true;
        
        this.setState({successMessage:''});

        if (!customerId.trim()) {
          formIsValid = false;
          formErrors["customerIdErr"] = "Please enter a valid Customer ID";
        }
        if (!stateCd.trim()) {
            formIsValid = false;
            formErrors["stateCdErr"] = "Please enter a valid state";
        }
        if (!countyName.trim()) {
            formIsValid = false;
            formErrors["countyNameErr"] = "Please enter a valid county";
        }
        if (!countyId.trim()) {
            formIsValid = false;
            formErrors["countyIdErr"] = "Please enter a valid County ID";
        }
      
        this.setState({ formErrors: formErrors });
        return formIsValid;
      }

      handleClliFormValidation() {
        const { customerId, stateCd, countyName, entity, clliCd } = this.state;
        let formErrors = {};
        let formIsValid = true;
        
        this.setState({successMessage:''});

        if (!customerId.trim()) {
          formIsValid = false;
          formErrors["customerIdErr"] = "Please enter a valid Customer ID";
        }
        if (!stateCd.trim()) {
            formIsValid = false;
            formErrors["stateCdErr"] = "Please enter a valid state";
        }
        if (!countyName.trim()) {
            formIsValid = false;
            formErrors["countyNameErr"] = "Please enter a valid county";
        }
        if (!clliCd.trim()) {
            formIsValid = false;
            formErrors["clliCdErr"] = "Please enter a valid clli";
        }
        if (!entity.trim()) {
            formIsValid = false;
            formErrors["entityErr"] = "Please enter a valid entity";
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
      }
      
      handleEntityFormValidation() {
        const { customerId, stateCd, countyName, entity } = this.state;
        let formErrors = {};
        let formIsValid = true;
        
        this.setState({successMessage:''});

        if (!customerId.trim()) {
          formIsValid = false;
          formErrors["customerIdErr"] = "Please enter a valid Customer ID";
        }
        if (!stateCd.trim()) {
            formIsValid = false;
            formErrors["stateCdErr"] = "Please enter a valid state";
        }
        if (!countyName.trim()) {
            formIsValid = false;
            formErrors["countyNameErr"] = "Please enter a valid county";
        }
        if (!entity.trim()) {
            formIsValid = false;
            formErrors["entityErr"] = "Please enter a valid entity";
        }
        
        this.setState({ formErrors: formErrors });
        return formIsValid;
      }

     handleExchangeFormValidation() {
        const { customerId, stateCd, countyName, exchange } = this.state;
        let formErrors = {};
        let formIsValid = true;
        
        this.setState({successMessage:''});

        if (!customerId.trim()) {
          formIsValid = false;
          formErrors["customerIdErr"] = "Please enter a valid Customer ID";
        }
        if (!stateCd.trim()) {
            formIsValid = false;
            formErrors["stateCdErr"] = "Please enter a valid state";
        }
        if (!countyName.trim()) {
            formIsValid = false;
            formErrors["countyNameErr"] = "Please enter a valid county";
        }
        if (!exchange.trim()) {
            formIsValid = false;
            formErrors["exchangeErr"] = "Please enter a valid exchange";
        }
        
        this.setState({ formErrors: formErrors });
        return formIsValid;
      }


    cancel(){
        if(this.props.addIdType=="_add"){
            this.props.setShowAddModal(false);
        }
        if(this.props.editIdType=="_edit"){
            this.props.onCloseEditModal();
        }
    }

    getTitle(){

        if(this.props.addIdType === '_add'){
            return "Add a New " +  this.props.entityType.replace(/^\w/, c => c.toUpperCase());
        }else{
            return "Edit " +  this.props.entityType.replace(/^\w/, c => c.toUpperCase());
        }
    }

    handleSubmit = (e) => {
        if(e){
           e.preventDefault();
        }
    };

      
    render() 

    {
        const {
            customerIdErr,
            agencyIdErr,
            stateCdErr,
            countyNameErr,
            countyIdErr,
            entityErr,
            clliCdErr,
            exchangeErr
          } = this.state.formErrors;

  
        return (
            <div>
                   <div className = "card-body">
            <form onSubmit={this.handleSubmit} id="cagencyForm">
            
                <Modal className={modalStyle.modal} show={this.props.addIdType=="_add"?this.props.showAddModal:this.props.showEditModal}>
                   <Modal.Header className={modalStyle.modalHeader}>
                        <Modal.Title className={modalStyle.modalTitle}>
                              <div className="flex px-2"  >
                               <span style={{ marginTop: '0.5rem' }}>
                                   {this.getTitle()}
                                </span>
                              </div>
                        </Modal.Title>
                   </Modal.Header>  
                   <Modal.Body className={modalStyle.modalBody}>
   
                                           {this.props.entityType == "agency" && this.agencyModalBody(agencyIdErr, customerIdErr)}
                                           {this.props.entityType == "county" && this.countyModalBody(customerIdErr, stateCdErr, countyNameErr, countyIdErr)}
                                           {this.props.entityType == "clli" && this.clliModalBody(customerIdErr, stateCdErr, countyNameErr, entityErr, clliCdErr)}                                          
                                           {this.props.entityType == "entity" && this.entityModalBody(customerIdErr, stateCdErr, countyNameErr, entityErr)}                                          
                                           {this.props.entityType == "exchange" && this.exchangeModalBody(customerIdErr, stateCdErr, countyNameErr, exchangeErr)}                                          
                                           
                                           <div className={style.errorHandler}>
                                            {this.state.message && (
                                                <div className="flex">
                                                    <div className="grid grid-cols-7 grid-rows-1 py-4">    
                                                        <div className="col-span-1">
                                                            <i className="far fa-times-circle fa-2x "></i>
                                                        </div>    

                                                        <div className="col-span-6 py-1">
                                                            {this.state.message}
                                                        </div>    
                                                    
                                                </div>    
                                                </div>
                                            )}
                                        </div>
                                        <div className={style.successHandler}>
                                            {this.state.successMessage && (
                                                <div className="flex">
                                                    <div className="grid grid-cols-7 grid-rows-1 py-4">    
                                                        <div className="col-span-1">
                                                            <i className="fa-solid fa-check-circle fa-2x "></i>
                                                        </div>    

                                                        <div className="col-span-6 py-1">
                                                            {this.state.successMessage}
                                                        </div>    
                                                    
                                                </div>    
                                                </div>
                                            )}
                                        </div>



                   </Modal.Body> 
                   <Modal.Footer className={modalStyle.modalFooter}>
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 px-2 py-2">
                                <Button  className={modalStyle.buttonPrimary} onClick={this.saveOrUpdateGeneral.bind(this)} >{this.state.buttonLabel}</Button>
                                <span className="px-4"></span>
                                <Button className={modalStyle.buttonSecondary} onClick={this.cancel.bind(this)} >Cancel</Button>
                           </div>
                        </div>
                        
                    </Modal.Footer>
                </Modal>
             </form>
       </div>
       <br></br>
       </div>
    )
    }    


   customerIdOption(customerIdErr,disabled) {
    return(

        <div  className = "form-group">
            <label className={modalStyle.modalLabel}> Customer ID  </label>
            <span className={modalStyle.requiredLabel}>*</span>
            <div>
                <input 
                    style={{borderColor: customerIdErr? 'red': '', fontSize: 12, "padding": 8, width:"150px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}}  
                    name="customerId" 
                    maxLength={20}
                    className={customerIdErr ? style["error"] : "form-control"}
                    value={this.state.customerId} 
                    onChange={this.changeCustomerIdHandler}
                    disabled={disabled}
                />
                {customerIdErr && (
                    <div className={style.showError}>
                        {customerIdErr}
                    </div>
                )}
            </div>
         </div>

    )

   } 

   stateOption(stateCdErr) {
    return(
        <div className = "form-group">
        <label className={modalStyle.modalLabel}> State </label>
        <span className={modalStyle.requiredLabel}>*</span>
        <div>
            <input 
                style={{fontSize: 12, "padding": 8, width:"180px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                name="stateCd" 
                maxLength={2}
                className={stateCdErr ?  style["error"] : "form-control"}
                value={this.state.stateCd} 
                onChange={this.changeStateCdHandler}
                disabled = {(this.props.editIdType==='_edit')? "disabled" : ""}
            />
        </div>
        {stateCdErr && (
            <div className={style.showError}>
                {stateCdErr}
            </div>
        )}
    </div>
    )
  }

  countyOption(countyNameErr, disabled){
    return(
        <div className = "form-group">
        <label className={modalStyle.modalLabel}> County </label>
        <span className={modalStyle.requiredLabel}>*</span>
        <div>
            <input 
                style={{fontSize: 12, "padding": 8, width:"250px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                placeholder="" 
                name="countyName" 
                maxLength={30}
                className={countyNameErr  ? style["error"] : "form-control"}
                value={this.state.countyName} 
                onChange={this.changeCountyNameHandler}
                disabled={disabled}
            />
        </div>    
        {countyNameErr && (
            <div className={style.showError}>
                {countyNameErr}
            </div>
        )}
    </div>
    )
  }

  communityOption(disabled){
   
   return(
    <div className = "form-group">
    <label className={modalStyle.modalLabel}> Community </label>
    <div>
        <input style={{fontSize: 12, "padding": 8, width:"280px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="community" 
            maxLength={32}
            className={"form-control"}
            value={this.state.community} 
            onChange={this.changeCommunityHandler}
            disabled={disabled}
        />
    </div>    
   </div>
   ) 
    
  }

  entityOption(entityErr, disabled){
    return(
        <div className = "form-group">
        <label className={modalStyle.modalLabel}> Entity </label>
        <span className={modalStyle.requiredLabel}>*</span>
        <div>
            <input 
                style={{fontSize: 12, "padding": 8, width:"250px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                placeholder="" 
                name="entity" 
                maxLength={3}
                className={entityErr  ? style["error"] : "form-control"}
                value={this.state.entity} 
                onChange={this.changeEntityHandler}
                disabled={disabled}

            />
        </div>    
        {entityErr && (
            <div className={style.showError}>
                {entityErr}
            </div>
        )}
    </div>

    )
  }

  agencyModalBody(agencyIdErr, customerIdErr) {

    return(

       <>
        <div  className = "form-group">
            <label className={modalStyle.modalLabel}> Agency ID  </label>
            <span className={modalStyle.requiredLabel}>*</span>
            <div>
                <input 
                    style={{borderColor: agencyIdErr? 'red': '', fontSize: 12, "padding": 8, width:"150px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}}  
                    name="agencyId" 
                    maxLength={50}
                    disabled = {(this.props.editIdType==='_edit')? "disabled" : ""}
                    className={agencyIdErr ? style["error"] : "form-control"}
                    value={this.state.agencyId} 
                    onChange={this.changeAgencyIdHandler}
                />
                {agencyIdErr && (
                    <div className={style.showError}>
                        {agencyIdErr}
                    </div>
                )}
            </div>
        </div>
        {this.customerIdOption(customerIdErr,this.props.editIdType==='_edit')}
        <div className = "form-group">
            <label className={modalStyle.modalLabel}> Agency Name </label>
            <div>
                <input 
                    style={{fontSize: 12, "padding": 8, width:"250px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                    name="agencyName" 
                    maxLength={300}
                    className={"form-control"}
                    value={this.state.agencyName} 
                    onChange={this.changeAgencyNameHandler}
                />
           </div>
        </div>
    <div className = "form-group">
        <label className={modalStyle.modalLabel}> MSAG Change File Input Location </label>
        <div>
        <textarea 
            style={{fontSize: 12, resize: "none",paddingTop: 0,paddingBottom: 0,width:"300px" ,height:"70px","marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="inputMsagAddrPtFileLoc" 
            maxLength={255}
            className={"form-control"}
            value={this.state.inputMsagAddrPtFileLoc?this.state.inputMsagAddrPtFileLoc:""} 
            onChange={this.changeInputMsagAddrPtFileLocHandler}
        />
        </div>    
    </div>

    <div className = "form-group">
        <label className={modalStyle.modalLabel}> MSAG Change File Output Location </label>
        <div>
        <textarea 
            style={{fontSize: 12, resize: "none","padding": 8, width:"300px" ,height:"70px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="outputMsagChgFileLoc" 
            maxLength={255}
            className={"form-control"}
            value={this.state.outputMsagChgFileLoc?this.state.outputMsagChgFileLoc:""} 
            onChange={this.changeOutputMsagChgFileLocHandler}
        />
        </div>    
    </div>

    <div className = "form-group">
        <label className={modalStyle.modalLabel}> Log Location </label>
        <div>
        <textarea style={{fontSize: 12, resize: "none","padding": 8, width:"300px" ,height:"70px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="outputLogFileLoc" 
            maxLength={255}
            className={"form-control"}
            value={this.state.outputLogFileLoc?this.state.outputLogFileLoc:""} 
            onChange={this.changeOutputLogFileLocHandler}/>

        </div>    
    </div>
    

    <div className = "form-group">
        <label className={modalStyle.modalLabel}> File Archive Location </label>
        <div>
        <textarea style={{fontSize: 12, resize: "none","padding": 8, width:"300px" ,height:"70px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="outputFileArchiveLoc" 
            maxLength={255}
            className={"form-control"}
            value={this.state.outputFileArchiveLoc?this.state.outputFileArchiveLoc:""} 
            onChange={this.changeOutputFileArchiveLocHandler}/>

        </div>    
    </div>
    
    <div className = "form-group">
        <label className={modalStyle.modalLabel}> Consolidate Steet Ranges </label>
        <div className={style.SwitchIcon}>
        <ReactSwitch
            name="consolidateStreetRanges" 
            checked={this.state.consolidateStreetRanges}
            value={this.state.consolidateStreetRanges}
            onChange={this.changeConsolidateStreetRangesHandler}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#582D82"
            checkedHandleIcon={<FaCheck/>}
            height={26}
            />

        </div>    
    </div>
    </>
    )
   } 

   countyModalBody(customerIdErr, stateCdErr, countyNameErr, countyIdErr){
    return(
        <>
        {this.customerIdOption(customerIdErr,this.props.editIdType==='_edit')}
        {this.stateOption(stateCdErr,this.props.editIdType==='_edit')}
        {this.countyOption(countyNameErr, this.props.editIdType==='_edit')}
    <div className = "form-group">
        <label className={modalStyle.modalLabel}> County ID </label>
        <span className={modalStyle.requiredLabel}>*</span>
        <div>
        <input style={{fontSize: 12, "padding": 8, width:"280px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
            placeholder="" 
            name="countyId" 
            maxLength={5}
            className={countyIdErr ?   style["error"] : "form-control"}
            value={this.state.countyId} 
            //disabled = {(this.props.editIdType==='_edit')? "disabled" : ""}
            onChange={this.changeCountyIdHandler}/>

        </div>    
        {countyIdErr && (
            <div className={style.showError}>
                {countyIdErr}
            </div>
        )}
    </div>
        </>
    )
   }

   clliModalBody(customerIdErr, stateCdErr, countyNameErr, entityErr, clliCdErr){
    return(
    <>
        {this.customerIdOption(customerIdErr,this.props.editIdType==='_edit')}
        {this.stateOption(stateCdErr)}
        {this.countyOption(countyNameErr, this.props.editIdType==='_edit')}
        {this.communityOption(this.props.editIdType==='_edit')}
        {this.entityOption(entityErr, this.props.editIdType==='_edit')}
    
        <div className = "form-group">
            <label className={modalStyle.modalLabel}> CLLI </label>
            <span className={modalStyle.requiredLabel}>*</span>
            <div>
                <input style={{fontSize: 12, "padding": 8, width:"280px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                    placeholder="" 
                    name="Clli" 
                    maxLength={11}
                    className={clliCdErr ?   style["error"] : "form-control"}
                    value={this.state.clliCd} 
                    //disabled = {(this.props.editIdType==='_edit')? "disabled" : ""}
                    onChange={this.changeClliCdHandler}/>
            </div>    
            {clliCdErr && (
                <div className={style.showError}>
                    {clliCdErr}
                </div>
            )}
        </div>      
    </>
    )
   
   }

   entityModalBody(customerIdErr, stateCdErr, countyNameErr, entityErr) {
   return(
   <>
        {this.customerIdOption(customerIdErr,this.props.editIdType==='_edit')}
        {this.stateOption(stateCdErr,this.props.editIdType==='_edit')}
        {this.countyOption(countyNameErr, this.props.editIdType==='_edit')}   
        {this.communityOption(this.props.editIdType==='_edit')}
        {this.entityOption(entityErr, false)}
   </>
   )

   }

   exchangeModalBody(customerIdErr, stateCdErr, countyNameErr, exchangeErr) {
   return(
   <>
        {this.customerIdOption(customerIdErr, this.props.editIdType==='_edit')}
        {this.stateOption(stateCdErr, this.props.editIdType==='_edit')}
        {this.countyOption(countyNameErr, this.props.editIdType==='_edit')}
        {this.communityOption(this.props.editIdType==='_edit')}

        <div className = "form-group">
        <label className={modalStyle.modalLabel}> Exchange </label>
        <span className={modalStyle.requiredLabel}>*</span>
        <div>
            <input style={{fontSize: 12, "padding": 8, width:"280px" ,"marginTop":"0.2em","marginLeft":"0.5em", "marginBottom":"2.5em"}} 
                placeholder="" 
                name="exchange" 
                maxLength={4}
                className={exchangeErr ?   style["error"] : "form-control"}
                value={this.state.exchange} 
                onChange={this.changeExchangeHandler}/>
        </div>    
        {exchangeErr && (
            <div className={style.showError}>
                {exchangeErr}
            </div>
        )}
        </div>
 </>
   )
 }
}

export default CreateModal;